<template>
  <div
    class="input-wrapper enext-input"
    :class="[
      (rules && rules.length) ? 'enext-input--validable': '',
      errorMessage? 'invalid': '',
      customClasses
    ]"
  >
    <!--needs to be added again-->
    <!--:class="{'enext-input--validable': this.rules && this.rules.length, 'invalid': this.errorMessage}"-->
    <div
      class="text__input"
      v-if="!multiline"
    >
      <span
        class="apply-changes-button"
        @click="triggerEvent"
        v-show="disableLiveUpdate && valChanged"
      >
        <NavigationCheck />
      </span>
      <div class="input-container">
        <ActionInfoFilledIcon
          class="input-icon"
          v-if="showHintText && isInputEmpty"
        />
        <k-input
          class="input"
          :class="{ 'input--empty': showHintText && isInputEmpty }"
          input-class="data-hj-allow"
          v-bind="{ name: uuid+'_inputtext', id: uuid+'_inputtext', ref: uuid + '_inputtext', ...customAttrs }"
          v-on="{ input: inputEvent }"
          autocomplete="off"
          @keydown.enter="triggerEvent"
          @keydown.tab="triggerEvent"
        />
      </div>

      <span
        v-if="showHintText && isInputEmpty"
        class="hint-text"
      >
        {{ hintText }}
      </span>
    </div>

    <textarea
      v-else
      v-on="{ input: inputEvent }"
      v-bind="{ name: uuid+'_inputtext', id: uuid+'_inputtext', ref: uuid + '_inputtext', ...customAttrs }"
      class="k-textbox input k-textarea data-hj-allow"
    />
    <ErrorMessage
      :error-message="errorMessage"
      class="enext-input__errormessage enext-input--checkbox__errormessage"
    />
  </div>
</template>

<script>
import {Input} from '@progress/kendo-vue-inputs'
import inputValidationMixin from '~/mixins/input_validation'
import ErrorMessage from '~/components/input-components/ErrorMessage'
import NavigationCheck from '~/assets/icons/navigation_check.svg?component'
import ActionInfoFilledIcon from '~/assets/icons/action_info_filled.svg?component'

export default {
  components: {
    ErrorMessage,
    'k-input': Input,
    NavigationCheck,
    ActionInfoFilledIcon
  },
  mixins: [inputValidationMixin],
  data() {
    return {
      valChanged: false,
      inputValue: ''
    }
  },
  inheritAttrs: false,
  props: {
    multiline: {
      type: Boolean,
      default: false,
      required: false
    },
    maxTextLength: {
      type: Number,
      default: -1,
      required: false
    },
    disableLiveUpdate: {
      type: Boolean,
      default: false,
      required: false
    },
    validable: {
      type: Boolean,
      default() {
        return false
      },
      required: false
    },
    showHintText: {
      type: Boolean,
      default: false,
      required: false
    },
    hintText: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    inputEvent(event) {
      let value = event.target.value
      if (this.maxTextLength > -1 && value?.length > this.maxTextLength) {
        value = value.substring(0, this.maxTextLength)
      }
      this.inputValue = value
      this.$emit('input-value', value)
      this.valChanged = true
    },
    triggerEvent() {
      this.$emit('focusOut')
      this.valChanged = false
    },
    async validateInput() {
      const inputValue = this.$refs[this.uuid + '_inputtext'].computedValue
      const isValid = await this.validate(inputValue)
      return isValid
    }
  },
  computed: {
    isInputEmpty() {
      return this.inputValue.trim() === '' && !this.customAttrs?.modelValue
    },
    customClasses() {
      return this.$attrs?.class ?? ''
    },
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  }
}
</script>

<style lang="scss">
.input-wrapper {
  width: 100%;

  .input-container {
    position: relative;
    width: 100%;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
    width: 1.1875rem;
    height: 1.1875rem;
    color: $color__fst__primary;
  }

  .input {
    width: 100%;
  }

  .input--empty input {
    border-color: $color__fst__primary !important;
    border-width: 0.0937rem;
    box-shadow: none;
  }

  .hint-text {
    color: $color__fst__primary;
    font-size: $base-text-font-size;
    margin-top: 0.25rem;
  }

  textarea.k-textbox.k-textarea {
    height: auto;
  }
}

</style>
